import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { transparentize, readableColor } from 'polished'
import styled from 'styled-components'
import { config, useSpring, animated } from 'react-spring'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Layout from '../components/layout'
import { Box, AnimatedBox, Button } from '../elements'
import { ChildImageSharp } from '../types'
import SEO from '../components/SEO'
import { loadStripe } from "@stripe/stripe-js"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlash } from '@fortawesome/free-solid-svg-icons'
import { faTruck } from '@fortawesome/free-solid-svg-icons'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

const PBox = styled(AnimatedBox)`
  max-width: 1400px;
  margin: 0 auto;
`

const ImageBox = styled(AnimatedBox)`
  max-width: 200px;
  margin: 0 ${props => props.theme.space[2]};
  padding: ${props => props.theme.space[2]} 0;
`

const ProductBox = styled(AnimatedBox)`
  background-color: ${props => props.theme.colors.store};
  background-image: ${props => props.theme.backgrounds[0]};
  max-width: 1400px;
  margin: 0 auto;
  height: 100vh;
  > p {
    line-height: 1.4;
    margin: 0 ${props => props.theme.space[2]};
  }
  .checkOut {
    position: relative;
    padding: ${props => props.theme.space[4]} 0;
    margin: 0 0 0 25vw;

    @media (max-width: ${props => props.theme.breakpoints[2]}) {
      margin: 0 0 0 35vw;
    }

    @media (max-width: ${props => props.theme.breakpoints[1]}) {
      margin: 0 0 0 40vw;
    }

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      margin: 0 0 0 28vw;
    }
  
  }
  .checkOut h3 {
    font-size: ${props => props.theme.fontSizes[1]};
    font-weight: 300;
    display: inline-block;
    border: 1px solid #000;
    border-radius: 1000rem;
    background: ${props => props.theme.colors.store};
    padding: ${props => props.theme.space[2]};
  }
  .fa-arrow-down {
    color: ${props => props.theme.colors.primary};
    position: absolute;
    font-size: 3rem;
    padding: ${props => props.theme.space[1]};
    margin: ${props => props.theme.space[6]} 0;
  }
  .productContent {
    padding: 20px 20px;
    background-color: ${props => props.theme.colors.store};
    border-radius: 6px;
    max-width: 400px;
    border-radius: 6px;
    box-shadow:
    0 2.5px 1.7px -5px rgba(0, 0, 0, 0.07),
    0 6px 4px -5px rgba(0, 0, 0, 0.05),
    0 11.3px 7.5px -5px rgba(0, 0, 0, 0.042),
    0 20.1px 13.4px -5px rgba(0, 0, 0, 0.035),
    0 37.6px 25.1px -5px rgba(0, 0, 0, 0.028),
    0 90px 60px -5px rgba(0, 0, 0, 0.02);
  }
  .productContent p, ul {
    list-style-type: none;
    line-height: 1.4;
    padding-inline-start: 0;
    margin: 0 ${props => props.theme.space[2]};
  }
  .productQuestion {
    color: #11589f;
    margin: 0 ${props => props.theme.space[2]};
    padding: ${props => props.theme.space[2]} 0 0 0;
    
  }
  .productQuestion a {
    color: #11589f;
  }
  .productQuestion a:hover, a:active {
    color: #fff;
  }
  .fa-slash {
    font-size: ${props => props.theme.fontSizes[3]};
    transform: rotate(98deg);
  }
  .fa-truck {
    font-size: 0.8rem;
    color: #11589f;
  }
  .lineBreak {
    border-top: 1px solid #11589f;
    min-width: 15vw;
    max-width: 200px;
    margin: ${props => props.theme.space[2]} 0;
  }
  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    padding-top: ${props => props.theme.space[6]};
    padding-bottom: ${props => props.theme.space[6]};
    height: auto;
  }
`

const Content = styled(Box)<{ bg: string }>`
  background-color: ${props => transparentize(0.0, props.bg)};

  .gatsby-image-wrapper {
      max-width: 900px;
      margin: 0 auto;
  }

  .gatsby-image-wrapper:not(:last-child) {
    margin-bottom: ${props => props.theme.space[10]};

    @media (max-width: ${props => props.theme.breakpoints[3]}) {
      margin-bottom: ${props => props.theme.space[8]};
    }
  }
`

const Tagline = styled(AnimatedBox)`
  letter-spacing: 0.05em;
  font-size: ${props => props.theme.fontSizes[1]};
  text-transform: uppercase;

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    padding: 0 ${props => props.theme.space[0]};
  }
`

const ArrowBox = styled(AnimatedBox)`
  display: inline-block;
  color:${props => props.theme.colors.primary};
  > span {
    position: relative;
    color: #000;
    font-size: ${props => props.theme.fontSizes[2]};
    padding: 0 ${props => props.theme.space[4]};
  }  
`
const ProductTitle = styled(animated.h1)`
margin: ${props => props.theme.space[2]} 0;
` 
const ProjectTitle = styled(animated.h2)<{ color: string }>`
color: ${props => readableColor('#ffffff','#211e26','#e7ebed')};
font-family: 'Work Sans', '-apple-system', 'Roboto', 'Helvetica', 'Arial', sans-serif;
font-size: ${props => props.theme.fontSizes[1]}; 
font-weight: 400;
margin: 0 ${props => props.theme.space[2]};
` 
const GalleryTitle = styled(animated.h4)<{ color: string }>`
color: ${props => readableColor('#ffffff','#211e26','#e7ebed')};
font-size: ${props => props.theme.fontSizes[2]}; 
` 
const Description = styled(animated.div)`
  max-width: 960px;
  letter-spacing: -0.003em;
  --baseline-multiplier: 0.179;
  --x-height-multiplier: 0.35;
  line-height: 1.58;
  padding: ${props => props.theme.space[8]};

  @media (max-width: ${props => props.theme.breakpoints[3]}) {
    padding: ${props => props.theme.space[9]};
  }
  > div {
    text-align: justify;
  }
  > div hr {
    border: 0;
    background-color: #11589f;
    height: 1px;
    margin-left: auto;
    margin-right: auto;
  }
`
const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  :hover {
    background: white;
    color: black;
    border: 1px solid #000000;
    transition: 0.5s;
    cursor: pointer;
  }
`

const CheckoutButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
  left: 0;
  bottom: 0;
  margin: 0 0 0 1rem;
  :hover {
    background: white;
    color: black;
    transition: 0.5s;
    cursor: pointer;
  }
`

type PageProps = {
  data: {
    store: {
      product_title: string
      product_tagline: string
      product_price: string
      product_sku: string
      product_size: string
      product_finish: string
      product_desc: string
      product_link: string
      parent: {
        modifiedTime: string
        birthTime: string
      }
      product_cover: ChildImageSharp
    }
    product_images: {
      nodes: {
        name: string
        childImageSharp: {
          fluid: {
            aspectRatio: number
            src: string
            srcSet: string
            sizes: string
            base64: string
            tracedSVG: string
            srcWebp: string
            srcSetWebp: string
          }
        }
      }[]
    }
  }
}

const Product: React.FunctionComponent<PageProps> = ({ data: { store, product_images } }) => {
  const taglineAnimation = useSpring({
    config: config.slow, delay: 100, 
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)'},
  })


  const titleAnimation = useSpring({ config: config.slow, delay: 200, from: { opacity: 0 }, to: { opacity: 1 } })
  const descAnimation = useSpring({ config: config.slow, delay: 600, from: { opacity: 0 }, to: { opacity: 1 } })
  const imagesAnimation = useSpring({ config: config.slow, delay: 400, from: { opacity: 0 }, to: { opacity: 1 } })

  const arrowAnimation = useSpring({
    config: config.slow, delay: 400,
    from: { opacity: 0, transform: 'translate3d(0, -30px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0px, 0)'},
  })

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISH_KEY)

const redirectToCheckout = async event => {
  event.preventDefault()
  const stripe = await stripePromise
  const { error } = await stripe.redirectToCheckout({
    lineItems: [{ price: store.product_sku, quantity: 1 }],
    billingAddressCollection: 'auto',
    shippingAddressCollection: {
      allowedCountries: ['IE','GB','FR'],
    },
    mode: 'payment',
    successUrl: `https://ascreative.studio/thankyou/`,
    cancelUrl: `https://ascreative.studio/error`,
  })

  if (error) {
    console.warn("Error:", error)
  }
}
  return (
    <Layout color="#e2e2e2">
      <SEO
        pathname={store.product_link}
        title={`${store.product_tagline} | ASC Studio Dublin`}
        desc={store.product_desc}
        node={store.parent}
        banner={store.product_cover.childImageSharp.fluid}
        individual
      />
      <ProductBox bg={"#e2e2e2"} py={8} px={[6, 6, 8, 8, 10, 12]}>
        <Tagline style={taglineAnimation}>{store.product_tagline}</Tagline>
        <ProductTitle style={titleAnimation}>{store.product_title} <FontAwesomeIcon icon={faSlash} /></ProductTitle>     
        <ProjectTitle style={titleAnimation}>Print Information:</ProjectTitle> 
        <ImageBox style={imagesAnimation} py={5}>
        <Img fluid={store.product_cover.childImageSharp.fluid} />
        </ImageBox>
        <div className="productContent">
          <ul>
            <li>High Quality Giclée printing.</li>
            <li>Fine 350 gsm 100% cotton rag paper.</li>
            <li>Original Irish Art.</li>
            <li>Limited Edition.</li>
            <li>Produced in Dublin, Ireland</li>
            <div className="lineBreak"></div>
          </ul>
          <ul>
            <li><span>Size:</span> {store.product_size}</li>
            <li><span>Finish:</span> {store.product_finish}</li>
            <li><span>Price: {store.product_price}</span> <i>with</i> <span>Free Shipping!</span> <FontAwesomeIcon icon={faTruck} /></li>
          </ul>
        
        <p className="productQuestion"><span>Have a question? be sure to <a href="/contact">get in touch</a>.</span> We would be delighted to assist!</p>
        </div>
        <ArrowBox style={arrowAnimation}><FontAwesomeIcon icon={faArrowDown} onClick={() => scrollTo('#gallery')} /></ArrowBox>  
        <div className="checkOut">
        <animated.h3 style={descAnimation}>{store.product_price}</animated.h3>
        <CheckoutButton color="#000000" py={2} px={8} onClick={redirectToCheckout}>
          Buy
        </CheckoutButton>
        </div>
      </ProductBox>
      <Content id="gallery" bg="#ffffff" py={0}>
        <PBox style={imagesAnimation} px={[6, 6, 8, 10]}>
        <GalleryTitle style={titleAnimation}>Gallery /</GalleryTitle> 
        <Description style={descAnimation}>
            <div dangerouslySetInnerHTML={{ __html: store.product_desc}} />
        </Description>
        <PBox px={[10, 10, 12, 12, 13, 13]}>
          {product_images.nodes.map(image => (
            <Img alt={image.name} key={image.childImageSharp.fluid.src} fluid={image.childImageSharp.fluid} />
          ))} 
          </PBox>
        </PBox>
      </Content>
      <PBox style={{ textAlign: 'center' }} py={8} px={[6, 6, 8, 10]}>
        <h3>Have a question?</h3>
        <Link to="/contact">
        <PButton color="#000000" py={3} px={8}>
        Contact Us
        </PButton>
        </Link>
      </PBox>
    </Layout>  
  )
}

export default Product

export const query = graphql`
  query StoreTemplate($product_link: String!, $product_images: String!) {
    store: storeYaml(product_link: { eq: $product_link }) {
      product_title
      product_tagline
      product_price
      product_sku
      product_size
      product_finish
      product_desc
      product_link
      parent {
        ... on File {
          modifiedTime
          birthTime
        }
      } 
      product_cover {
        childImageSharp {
          fluid(quality: 95, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      } 
    }
    product_images: allFile(filter: { relativePath: { regex: $product_images } }, sort: { fields: name, order: ASC }) {
      nodes {
        name
        publicURL
        childImageSharp {
          fluid(quality: 95, maxHeight: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }

`
